<template>
  <a-spin :spinning="loading">
    <div class="reader">
      <preview-txt v-if="docType === 'txt'" :file-url="url"></preview-txt>
      <preview-pdf v-else-if="docType === 'pdf'" :file-url="url"></preview-pdf>
      <iframe v-else-if="docType !== ''" :src="url" class="iframe"></iframe>
    </div>
  </a-spin>
</template>

<script>
import { UserUploadRecordModel } from '@/models/user_upload_record.js'
import {message} from 'ant-design-vue'

const userUploadRecordModel = new UserUploadRecordModel()

export default {
  components: {
    PreviewTxt: () => import('@/pages/document/list/details/preview/Txt'),
    PreviewPdf: () => import('@/pages/document/list/details/preview/Pdf'),
  },
  data() {
    return {
      loading: true,
      url: '',
      docType: '',
    }
  },
  mounted() {
    this.fetchData()
  },
  computed: {
    documentId() {
      return parseInt(this.$route.params.id)
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      userUploadRecordModel.getDocPreviewFileUrl(this.documentId).then(res => {
        if (res.code === 0) {
          if (res.result.doc_type === 'txt' || res.result.doc_type === 'pdf') {
            this.url = res.result.url
            this.docType = res.result.doc_type
          } else {
            this.url = 'http://view.officeapps.live.com/op/view.aspx?src=' + encodeURIComponent(res.result.url)
            this.docType = res.result.doc_type
          }

        } else {
          message.error({
            content: res.message,
            duration: 5
          })
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.reader {
  width: 945px;
  margin: 0 auto;

  .iframe {
    height: 700px;
    width: 100%;
    border: none;
  }
}
</style>